.spinner-container {
    position: relative;
}

.spinner-child {
    filter: blur(2px);
    user-select: none;
    pointer-events: none;
}

.spinner-container .spinner-border {
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1rem 0 0 -1rem;
}

.spinner-container .spinner-child .spinner-border {
    display: none;
}
