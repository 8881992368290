.filters {
  display: flex;
  input,select{
    padding-left: 4px;
    padding-right: 4px;
  }
  select.custom-select{
    background-position-x: 95%;
  }
  >div{
    margin: 3px;
  }
  .fields {
    width: 110px;
    flex: 0 0 auto;
  }

  .operators {
    width: 105px;
    flex: 0 0 auto;

  }

  .filter {
    flex: 1 1 auto;
  }

  .submit {
    flex: 0 0 auto;
  }
}
