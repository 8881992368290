@import "_variables.scss";

// Required
//@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$input-focus-box-shadow: inset 0 0 0 0.1rem $input-btn-focus-color;

@import "~bootstrap/scss/mixins";

//
//// Optional
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/custom-forms";


@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/spinners";


.pointer {
  cursor: pointer;

}

.content-centered {
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone-content {
  position: relative;
}

.dropzone-content.with-image .dropzone-text {
  background-color: white;
  opacity: 0;
  transition: opacity .300s;
}

.dropzone-content.with-image .dropzone-text:hover {
  opacity: .8;
}

.dropzone-text {
  position: absolute;
  text-align: center;
  padding: 70px 25px;
}

.dropzone-image {
  position: absolute;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.no-data {
  text-align: center;
  padding: 80px !important;
}

.checkbox-inline label input, .checkbox label input {
  margin-right: 8px;
}


.avatar {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  flex-direction: column;

  img {
    width: 100%;
    height: 100%;
    max-width: 150px;
    max-height: 150px;
    border-radius: 100px;
    border: 1px solid #4e575b;
  }
}

.inline {
  display: inline-block;
}


.text-inline.form-group {
  display: inline-block;
}

.text-inline.form-group [name$='unit'] {
  padding-left: 4px;
  padding-right: 4px;
  width: 63px
}

.text-inline.form-group [name$='number'] {
  padding-left: 4px;
  padding-right: 4px;
  width: 65px;
  margin-right: 5px;
}

a {
  cursor: pointer;
}

.fluid-input-submit {
  display: flex;

  .fluid-input {
    flex: 1 1 auto;
    max-width: 200px;
    min-width: 100px;
  }

  .fluid-submit {
    margin-top: 27px;
    flex: 0 0 auto;
  }
}

#basic-navbar-nav {
  .navbar-nav {
    text-align: right;

    .nav-item.dropdown > div {
      align-self: flex-end;

      svg {
        width: 210px;
      }
    }
  }
}

.install-app-container {
  background: $secondary;
  color: white;
  border: 1px solid #919aa1;
  display: flex;
  flex-wrap: wrap;

  .install-app-add {
    text-decoration: underline;
    font-weight: bold;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    flex: 1 1 auto;

    ::content {
      align-items: center;
      justify-items: center;
    }
  }

  .install-app-cancel {
    flex: 0 0 auto;
  }
}

table th button.btn.btn-link {
  padding: 0;
}

.confirm-input {
  .form-check {
    margin-top: 12px;
  }

  .form-group {
    margin-bottom: 0 !important;
  }
}

.form-control-like {
  height: 45px;
  color: #55595c;
  background-color: #f7f7f9;
  border: 0 solid #ced4da;
}

.popover {
  max-width: 310px !important;
}

h1 {
  color: $secondary
}
a.dropdown-item:active{
  background-color: transparent !important;
  color: $secondary;
}
.dropdown-item.active {
  background-color: transparent !important;
  color: $secondary;
  font-weight: bold;
}

.purple {
  color: purple;
}

.dropdown-menu-right {
  right: -18px;
}

.navbar-nav .dropdown-menu {
  position: inherit;
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
  filter: invert(100%);
}

.carousel-indicators li.active{
  filter: invert(70%);
}
.carousel-indicators li{
  filter: invert(100%);
}

span.required{
  color: crimson;
  font-size: small;
  font-variant-position: super
}

.text-uppercase label{
  text-transform: none;
}
.text-uppercase input{
  text-transform: uppercase;
}


.black-backdrop{
  opacity: .85 !important;
}