.directory-container{
  position: relative;
  display: flex;
}
.directory-list .directory-container{
  margin-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
}
.directory-image{
  text-align: center;
  flex: 0;
  flex-basis: 20%;
  max-width: 150px;
  min-width: 70px;

}
.directory-image svg{
  width: 100%;
  height: 100%;
}
.directory-description{
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  flex: 1;
  .content{
    flex: 1 0;
  }
  .assigned-funds{
    flex: 0 1
  }

}
.directory-favorite{
  flex: 0;
  display: flex;
  flex-direction: column;
  top: 0;
  right:0;
  .icon-text{
    text-align: center;
    flex-direction: column;
    display: flex;
    font-size: 9px;
  }
}
