.marginBottom2 {
  margin-bottom: 20px;
}

.search-inline {
  input,select{
    box-shadow: none;
    border: 0;
  }
  display: flex;
  flex-direction: row;
  height: 45px;
  .input-group-append{
    background-color: #f7f7f9;
  }
  input, select {
    transition: background .7s
  }
  input:hover, select:hover {
    background: #dfe5ea;
  }

  > .form-group {
    display: inline-block;
  }

  > div:first-child {
    flex: 1 3 auto;
    position: relative;
    .clear-query{
      background-color: transparent;
      height: 40px;
      width: 14px;
      padding: 0;
      margin: 0;
      top: 2px;
      position: absolute;
      right: 2px;
    }
  }

  .text-address {
    width: calc(100% - 16px);
    cursor: text;
  }

  .col button.form-control-like {
    top: -12px;
    position: absolute;
  }

  > div.distance-search {
    position: relative;

    .close {
      position: absolute;
      right: 0
    }

    flex: 1 1 auto;
    display: flex;

    > div.form-group:first-child {
      flex: 1 1 auto;
    }

    > div.form-group:last-child {
      flex: 0 0 auto;
    }

    .form-inline .form-control-like.form-row {
      width: 100%;

      .col {
        align-self: center;
      }
    }

    .form-group {
      margin-bottom: 0;
    }
  }

  input {
    padding-left: 5px;
    padding-right: 0;
  }

  select[name="distance.range"] {

    width: 85px;
    padding-left: 5px;
    padding-right: 0;
  }

  .input-group-append {
    width: 20px;
    button.form-control-like {
      width: 15px;

    }
  }
}

.all-bordered{
  border: 1px solid #ced4da;
}
