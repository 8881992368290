.votes{
  width: 40px;
  height: 40px;
  .votes-icon{
  }
  .votes-button{
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    overflow: hidden;
    transition: all .1s ease-in;
    transform-origin: right;
    text-align: right;
    .remove{
      display: none;
    }
  }
}

.directory-container:hover .votes-button{
  transition: none;
  width: 240px;
  .remove{
    display: inline-block;
  }
}

