.with-footer{
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex: 1;
  .with-footer-content{
    flex: 1 1;
  }
  .with-footer-help{
    flex: 0 0
  }
  .dark-footer{
    .version{
      color: rgb(65,65,65);
      position: absolute ;
      bottom: 0;
      right: 0;
      font-size: 9px;
    }
    position: relative;
    padding: 20px 0;
    margin-top: 50px;
    background-color: #55595c;
    color: white;
    a{
      line-height: 25px;
      color: white !important;
    }
  }
}
