.loading-tfoot{
  filter: blur(2px);
  user-select: none;
  pointer-events: none;
  position: relative;
}
.loading-tfoot-spinner{
  top: 25%;
  left: 50%;
  position: absolute;
}
.total{
  font-size: 1.1em;
}
th button.btn.btn-link{
  text-align: left;
  text-decoration: underline;
}

td a{
  text-decoration: underline;
}